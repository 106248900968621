<template>
  <!--begin::Card-->
  <div class="card border border-2 border-gray-300 border-hover mb-6 p-5">
    <a href="#" @click.prevent="setOrganization">
      <!--begin:: Card body-->
      <div class="card-body p-5 pb-3">
        <!--begin::Name-->
        <div class="fs-3 fw-bolder text-dark text-center">
          {{ organization.name }}
        </div>
        <template
          v-for="(industry, industryIndex) in industries"
          :key="industryIndex"
        >
          <p
            v-if="organization.industry === industry.key"
            class="text-gray-400 fw-bold fs-5 mt-1 mb-0 text-center"
          >
            {{ translate(`industria.${industry.key}`) }}
          </p>
        </template>
        <!--end::Name-->
      </div>
    </a>
    <div v-if="organization.status === 'accepted'" class="mx-12 mb-6">
      <div class="d-flex justify-content-around">
        <div class="text-center mt-4">
          <span :class="`text-${progressColor}`">
            <small class="me-1">{{ translate("absolute") }}:</small>
            <b>{{ parseInt(organization.progress.absolute) }}</b> %</span
          >
        </div>

        <div class="text-center mt-4">
          <span :class="`text-${progressColor}`">
            <small class="me-1">{{ translate("relative") }}:</small>
            <b>{{ parseInt(organization.progress.relative) }}</b> %</span
          >
        </div>
      </div>
    </div>
    <div v-if="organization.status == 'pending'" class="text-center mb-10">
      <span
        style="
          line-height: 1.2;
          color: #000;
          display: block;
          margin-bottom: 15px;
          font-size: 16px;
        "
        >{{ translate("youHaveARequestToJoinThisOrganization") }}</span
      >
      <button
        type="button"
        id="refuse_organization_button"
        class="btn btn-danger ps-6 me-1"
        @click.prevent="resolveInvitation('rejected')"
      >
        <span class="indicator-label"> Rechazar </span>
      </button>
      <button
        type="button"
        id="accept_organization_button"
        class="btn btn-success ps-6 ms-1"
        @click.prevent="resolveInvitation('accepted')"
      >
        <span class="indicator-label"> Aceptar </span>
      </button>
    </div>
  </div>
  <!--end::Card-->
</template>

<style scoped>
.symbol {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.col-xl-4 {
  margin-bottom: 15px !important;
}
</style>

<script>
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import ProgressBar from "@/components/app/ui/ProgressBar.vue";

/* eslint-disable */
export default {
  components: {
    ProgressBar,
  },
  props: {
    organization: {
      type: Object,
      default: () => { },
    },
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const industries = [
      {
        key: "Aeroespacial-y-Defensa",
        name: "Aeroespacial y Defensa"
      },
      {
        key: "Agricultura-y-Alimentacion",
        name: "Agricultura y Alimentación"
      },
      {
        key: "Alta-Tecnologia",
        name: "Alta Tecnología"
      },
      {
        key: "Automotriz",
        name: "Automotriz"
      },
      {
        key: "Bancos-y-Valores",
        name: "Bancos y Valores"
      },
      {
        key: "Call-Center-y-Atencion-al-Cliente",
        name: "Call Center y Atención al Cliente"
      },
      {
        key: "Casas-Inteligentes-y-Domotica",
        name: "Casas Inteligentes y Domótica"
      },
      {
        key: "Ciberseguridad",
        name: "Ciberseguridad"
      },
      {
        key: "Capacitacion-para-Empresas-y-Organizaciones",
        name: "Capacitación para Empresas y Organizaciones"
      },
      {
        key: "Construccion-e-Inmobiliario",
        name: "Construcción e Inmobiliario"
      },
      {
        key: "Consultoria-y-Servicios-Profesionales",
        name: "Consultoría y Servicios Profesionales"
      },
      {
        key: "Contabilidad-y-Finanzas",
        name: "Contabilidad y Finanzas"
      },
      {
        key: "Deportes-y-Bienestar",
        name: "Deportes y Bienestar"
      },
      {
        key: "Distribucion-y-Mayorista",
        name: "Distribución y Mayorista"
      },
      {
        key: "Educacion-y-Formacion",
        name: "ducación y Formación"
      },
      {
        key: "Energia-Quimicos-y-Utilidades",
        name: "Energía, Químicos y Utilidades"
      },
      {
        key: "Energias-Renovables-y-Medio-Ambiente",
        name: "Energías Renovables y Medio Ambiente"
      },
      {
        key: "Fabricacion-e-Industria",
        name: "Fabricación e Industria"
      },
      {
        key: "Gobierno-y-Administracion-Publica",
        name: "Gobierno y Administración Pública"
      },
      {
        key: "Hotel-Viajes-y-Turismo",
        name: "Hotel, Viajes y Turismo"
      },
      {
        key: "Informatica-Software-y-Tecnologia-de-la-Informacion-(IT)",
        name: "Informática, Software y Tecnología de la Información (IT)"
      },
      {
        key: "Investigacion-y-Desarrollo-(I+D)",
        name: "Investigación y Desarrollo (I+D)"
      },
      {
        key: "Legal-y-Cumplimiento",
        name: "Legal y Cumplimiento"
      },
      {
        key: "Logistica-y-Cadena-de-Suministro",
        name: "Logística y Cadena de Suministro"
      },
      {
        key: "Marketing-Publicidad-y-RRPP",
        name: "Marketing, Publicidad y RRPP"
      },
      {
        key: "Medicina-Farmacia-Biotecnologia-y-Salud",
        name: "Medicina, Farmacia, Biotecnología y Salud"
      },
      {
        key: "Medios-de-Comunicacion-y-Entretenimiento",
        name: "Medios de Comunicación y Entretenimiento"
      },
      {
        key: "Mineria-y-Recursos-Naturales",
        name: "Minería y Recursos Naturales"
      },
      {
        key: "Organizaciones-Sin-Fines-de-Lucro",
        name: "Organizaciones Sin Fines de Lucro"
      },
      {
        key: "Outsourcing-y-Soporte-Técnico",
        name: "Outsourcing y Soporte Técnico"
      },
      {
        key: "Retail-y-Comercio",
        name: "Retail y Comercio"
      },
      {
        key: "Seguros-y-Servicios-Financieros",
        name: "Seguros y Servicios Financieros"
      },
      {
        key: "Telecomunicaciones-y-Redes",
        name: "Telecomunicaciones y Redes"
      },
      {
        key: "Transporte-y-Movilidad",
        name: "Transporte y Movilidad"
      }
    ];
    return {
      translate,
      industries
    };
  },
  methods: {
    resolveInvitation(choose) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/respondinvitation/`;
      const currentToken = JwtService.getToken();
      const body = {
        organization: this.organization.id,
        answer: choose,
      };
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken,
        },
        body: JSON.stringify(body),
      }).then(() => {
        this.emitter.emit("load-organizations");
      })
    },

    setOrganization() {
      this.$store.dispatch(Actions.LOADED_OBJECTIVE, false);
      //organization.status == 'accepted' ? `#/organization/${organization.id}` : 'javascript:void(0);'
      this.$store.dispatch(Actions.LOAD_ORGANIZATION, {
        organization: this.organization,
        id: null,
      });
      this.$router.push("/organization/" + this.organization.id);
    },

    resolveInvitation(choose) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations/respondinvitation/`;
      const currentToken = JwtService.getToken();
      const body = {
        organization: this.organization.id,
        answer: choose,
      };
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken,
        },
        body: JSON.stringify(body),
      })
        .then(() => {

          this.emitter.emit("load-organizations");
        })
        .catch((error) => {

        });
    },

    startOrganizationEdition(organization) {
      this.emitter.emit("open-organization-modal", organization);
    },

    startOrganizationRemoving(organization) {
      this.emitter.emit("open-organization-removing-modal");
    },

    translate(text) {
      const { t, te } = useI18n();
      return te(text) ? t(text) : text;
    },
  },
  computed: {
    progressColor() {
      if (!this.organization || !this.organization.progress) {
        return "text-primary";
      }

      if (parseInt(this.organization.progress) <= 33) {
        return "text-danger";
      } else if (
        parseInt(this.organization.progress) > 33 &&
        parseInt(this.organization.progress) <= 66
      ) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
  },
};
</script>
