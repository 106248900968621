
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import OrganizationNewForm from "@/components/app/Organizations/OrganizationNewForm.vue";

export default defineComponent({
  name: "_app_organization_form_modal",
  components: {
    OrganizationNewForm
  },
  props: {
    organization: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isEditionEnabled: true
    };
  },
  methods: {
    saveOrganization(organizationToSave) {
      this.$emit("onSave", organizationToSave);
    }
  },
  mounted() {
    /*const name = <HTMLInputElement>document.getElementById('organization-name');
    if (name != null){
      name.value = ""
    }
    const industry = <HTMLSelectElement>document.getElementById('industry_selector');
    if (industry != null){
      industry.selectedIndex = 0
    }

    const empleados = <HTMLSelectElement>document.getElementById('empleados');
    if (empleados != null){
      empleados.selectedIndex = 0
    }

    const country = <HTMLSelectElement>document.getElementById('country');
    if(country != null){
      country.selectedIndex = 0
    }

    const motivo = <HTMLInputElement>document.getElementById('motivo');
    if(motivo != null){
      motivo.value = ""
    }
    
    const myModalEl = <HTMLElement>document.getElementById('_app_organization_new_form_modal');

    if(myModalEl != null){
      myModalEl.addEventListener('hidden.bs.modal', event => {
        const submitForm = <HTMLFormElement>document.getElementById("kt_organization_new_form");
        if(submitForm != null) {
          submitForm.reset()
        }
      })
    }*/
  },
  setup() {
    const { t, te } = useI18n();

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const current = ref<"month" | "annual">("month");

    const selected = ref("Startup");

    const plans = [
      {
        title: "Startup",
        subTitle: "Best for startups",
        description: "Optimal for 10+ team size and new startup",
        priceMonth: "39",
        priceAnnual: "399",
        default: true,
        custom: false,
        features: [
          {
            title: "Up to 10 Active Users",
            supported: true
          },
          {
            title: "Up to 30 Project Integrations",
            supported: true
          },
          {
            title: "Analytics Module",
            supported: true
          },
          {
            title: "Finance Module",
            supported: false
          },
          {
            title: "Accounting Module",
            supported: false
          },
          {
            title: "Network Platform",
            supported: false
          },
          {
            title: "Unlimited Cloud Space",
            supported: false
          }
        ]
      },

      {
        title: "Advanced",
        subTitle: "Best for 100+ team size",
        description: "Optimal for 100+ team size and grown company",
        priceMonth: "339",
        priceAnnual: "3399",
        default: false,
        custom: false,
        features: [
          {
            title: "Up to 10 Active Users",
            supported: true
          },
          {
            title: "Up to 30 Project Integrations",
            supported: true
          },
          {
            title: "Analytics Module",
            supported: true
          },
          {
            title: "Finance Module",
            supported: true
          },
          {
            title: "Accounting Module",
            supported: true
          },
          {
            title: "Network Platform",
            supported: false
          },
          {
            title: "Unlimited Cloud Space",
            supported: false
          }
        ]
      },

      {
        title: "Enterprise",
        subTitle: "Best value for 1000+ team",
        description: "Optimal for 1000+ team and enterpise",
        priceMonth: "999",
        priceAnnual: "9999",
        label: "Most popular",
        default: false,
        custom: false,
        features: [
          {
            title: "Up to 10 Active Users",
            supported: true
          },
          {
            title: "Up to 30 Project Integrations",
            supported: true
          },
          {
            title: "Analytics Module",
            supported: true
          },
          {
            title: "Finance Module",
            supported: true
          },
          {
            title: "Accounting Module",
            supported: true
          },
          {
            title: "Network Platform",
            supported: true
          },
          {
            title: "Unlimited Cloud Space",
            supported: true
          }
        ]
      },

      {
        title: "Custom",
        subTitle: "Requet a custom license",
        default: false,
        custom: true
      }
    ];

    return {
      plans,
      current,
      selected,
      translate
    };
  }
});
