import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "_app_organization_form_modal",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content rounded" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body pt-0 pb-15 px-5 px-xl-20" }
const _hoisted_8 = { class: "my-12 text-center" }
const _hoisted_9 = { class: "text-gray-400 fw-bold fs-5" }
const _hoisted_10 = { class: "d-flex flex-column" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")
  const _component_OrganizationForm = _resolveComponent("OrganizationForm")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("h2", null, _toDisplayString(_ctx.organization
                ? _ctx.translate("editingOrganization")
                : _ctx.translate("creatingOrganization")), 1),
          _createVNode("div", _hoisted_5, [
            _createVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Close.svg" })
            ])
          ])
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode("div", _hoisted_8, [
            _createVNode("div", _hoisted_9, _toDisplayString(_ctx.translate("manageOrganization")), 1)
          ]),
          _createVNode("div", _hoisted_10, [
            _createVNode("div", _hoisted_11, [
              _createVNode("div", _hoisted_12, [
                _createVNode(_component_OrganizationForm, {
                  organization: _ctx.organization,
                  "is-edition-enabled": _ctx.isEditionEnabled,
                  "is-cancel-button-visible": false,
                  onOnStopEdition: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditionEnabled = !_ctx.isEditionEnabled)),
                  onOnSave: _ctx.saveOrganization
                }, null, 8, ["organization", "is-edition-enabled", "onOnSave"])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}